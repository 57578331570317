import axios from "axios";
import { getAuthToken, uriAPI } from "./util";


    export const axiosHttp = axios.create({
        baseURL: uriAPI(),
    });
    axiosHttp.interceptors.request.use(function (config) {
        config.headers['cu-access-token'] = getAuthToken();
        return config;
    });
    
    /*axiosHttp.interceptors.response.use(
        response => {
            return response
          },function (error) {
            if (error.status === 401){
                useAuth.login();
                return Promise.reject(error)
            }else{
                useAuth.toggleError(true, error);
            }
          }
    );*/

