import { useEffect } from "react";
import { useAuth } from "../provider/Authprovider";
import { useAppContext } from "../provider/AppContextProvider";
//import axios from "axios";
import { axiosHttp } from "../AxiosWrapper";

const AxiosErrorHandler = ({ children }) => {
    const {login} = useAuth();
    const {toggleError} = useAppContext();

    useEffect(() => {
        const responseInterceptor = axiosHttp.interceptors.response.use(response =>{ 
            
                return response
           
        }, (error) => {
            if (error.status === 401){
                 login();
            }else{
                toggleError(true, error);
            }
            return Promise.reject(error)
        })

        return () => {
            axiosHttp.interceptors.response.eject(responseInterceptor);
        }
    }, [])

    return children
}
export default AxiosErrorHandler;


/*axiosHttp.interceptors.response.use(
        response => {
            return response
          },function (error) {
            if (error.status === 401){
                useAuth.login();
                return Promise.reject(error)
            }else{
                useAuth.toggleError(true, error);
            }
          }
    );*/

