import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import { AuthProvider } from "./provider/Authprovider";
import AxiosErrorHandler from "./error/AxiosErrorHandler";
import {AppContextProvider} from "./provider/AppContextProvider";
import { GoogleOAuthProvider } from "@react-oauth/google"

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
    <GoogleOAuthProvider clientId='949770729633-83bus590f2cgdeh0432m6if4h4crbf0o.apps.googleusercontent.com'>

    <AuthProvider>
    <AppContextProvider>
    <AxiosErrorHandler>
          <App />
      </AxiosErrorHandler>
      </AppContextProvider>
      </AuthProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
