import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "../provider/Authprovider";
import { localdb } from "../util";
const Authorization = ({ permissions, children }) => {
    //const { user } = useAuth();
    const location = useLocation();
    let user = JSON.parse(localdb.get("user"));
    if (user) {
        const userpermission = user.permConfig;
        const isAllowed  = userpermission && user.permConfig[children.key] ? true : false;
        //const isAllowed = userpermission ? permissions.some((allowed) => userpermission.includes(allowed)) : false;
        if(isAllowed){
            return children;
        }
        return <Navigate to="/dss/forbidden"/>;
    }
    return <Navigate to="/dss/login" state={{ path: location.pathname }} replace />;
};
export default Authorization;
