import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Authentication from "./Authentication.js";
import Authorization from "./Authorization.js";


/****Layouts*****/
const HomeLayout = lazy(() => import("../layouts/HomeLayout.js"));
const DataSecurityLayout  = lazy(() => import("../layouts/DataSecurityLayout.js"));
const AdmiHomeLayout = lazy(() => import("../layouts/AdminHomeLayout.js"));
const AdminCurriculumDetailsLayout = lazy(() => import("../layouts/AdminCurriculumDetailsLayout.js"));
const AdminUsersLayout = lazy(() => import("../layouts/AdminUsersLayout.js"));


/*****Routes******/

/**Pages*/
const LoginView = lazy(() => import("../views/dss/login/LoginView.js"));
const RegisterView = lazy(() => import("../views/dss/register/RegisterView.js"));
const RegisterSuccessView = lazy(() => import("../views/dss/register/RegisterSuccessView.js"));
const ProfileActivationView = lazy(() => import("../views/dss/register/ProfileActivationView.js"));
const ForgotUserNameView = lazy(() => import("../views/dss/forgotUserName/ForgotUserNameView.js"));
const ForgotPasswordView = lazy(() => import("../views/dss/forgotPassword/ForgotPasswordView.js"));
const UnauthorizedView = lazy(() => import("../views/dss/UnauthorizedView.js"));

const HomeView = lazy(() => import("../views/user/HomeView.js"));
const CurriculumDetailsView = lazy(() => import("../views/user/details/CurriculumDetailsUserView.js"));

const FormSubmitView = lazy(() => import("../views/FormSubmitView.js"));
const FormViewerView = lazy(() => import("../views/FormViewerView.js"));

const AdminCurriculumView = lazy(() => import("../views/admin/curriculum/CurriculumAdminView.js"));
const AdminCurriculumDetailsView = lazy(() => import("../views/admin/curriculum/details/CurriculumDetailsView.js"));
const AdminCurriculumSubmissionView = lazy(() => import("../views/admin/curriculum/details/submission/CurriculumSubmissionView.js"));
const AdminUsersView =  lazy(() => import("../views/admin/users/AdminUsersView.js"));

const FormBuilderView = lazy(() => import("../views/admin/FormBuilderView.js"));



const ThemeRoutes = [
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      { path: "", exact: true, element: <HomeView /> },
      { path: "/curriculum/*", exact: true, element: <CurriculumDetailsView/> },
      { path: "/curriculum/formSubmit/*", exact: true, element: <FormSubmitView /> },
      { path: "/curriculum/formView/*", exact: true, element: <FormViewerView /> }
    ],
  },
  {
    path: "/dss",
    element: <DataSecurityLayout />,
    children: [
      { path: "/dss/login", exact: true, element:<LoginView />},
      { path: "/dss/forgot-username", exact: true, element:<ForgotUserNameView />},
      { path: "/dss/forgot-password", exact: true, element:<ForgotPasswordView />},
      { path: "/dss/register", exact: true, element:<RegisterView />},
      { path: "/dss/register/success", exact: true, element:<RegisterSuccessView />},
      { path: "/dss/profile/activate", exact: true, element:<ProfileActivationView />},
      { path: "/dss/forbidden", exact: true, element:<UnauthorizedView/>}
    ]
  },
  {
    path: "/admin",
    element: <Authentication><AdmiHomeLayout /></Authentication>,
    children: [
      { path: "", exact: true,  element: <Navigate to="curriculums" replace />},
      { path: "curriculums", exact: true, element:<Authorization><AdminCurriculumView key="AdminCurriculumView"/></Authorization>},
    ]
  },
  {
    path: "/admin/curriculum",
    element: <Authentication><AdminCurriculumDetailsLayout/></Authentication>,
    children: [
      { path: "", exact: true,  element: <Navigate to="*" replace />},
      { path: "*", exact: true, element: <Authorization><AdminCurriculumDetailsView key="AdminCurriculumDetailsView"/></Authorization> },
      { path: "/admin/curriculum/sub/*", exact: true,  element: <Authorization><AdminCurriculumSubmissionView key="AdminCurriculumSubmissionView" /></Authorization>},
    ]
  },
  {
    path: "/admin/users",
    element: <Authentication><AdminUsersLayout/></Authentication>,
    children: [
      { path: "", exact: true, element:<Authorization><AdminUsersView key="AdminUsersView"/></Authorization>},
      { path: "/admin/users/*", exact: true, element:<Authorization><AdminUsersView key="AdminUsersView"/></Authorization>}
    ]
  }
];

export default ThemeRoutes;
