import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { localdb, TOKEN_HEADER_KEY } from "../util";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const redirectPath = location.state?.path ;
    const [user, setUser] = useState(null);
    const login = (userData) => {
        if (!userData || !userData.user) {
            return navigate('/dss/login', { state: { path: location.pathname } });
        }
        setUser(userData.user);
        localdb.put(TOKEN_HEADER_KEY, userData.token);
        //adding loggedIn time
        userData.user.loggedOn = Date.now();
        localdb.put("user", JSON.stringify(userData.user));
        
        if( "/dss/login" === redirectPath){
            redirectPath = null;
        }

        let path = redirectPath || (userData?.user?.isAdmin ? "/admin" : "/");
        navigate(path, { replace: true });
    };
    const logout = () => {
        localdb.remove("user");
        //setUser(null);
        return navigate('/dss/login');
    };

    const isLoggedIn = () => {
        let user = JSON.parse(localdb.get("user"));
        if (!user || !user.loggedOn || !user.sessionExpiredIn) {
            return false;
        }
        return (Date.now() - user.loggedOn) < parseInt(user.sessionExpiredIn);
    }

    const getUser = () =>{
       return JSON.parse(localdb.get("user"));
    }

    return <AuthContext.Provider value={{ getUser, login, logout, isLoggedIn }}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
    return useContext(AuthContext);
};